import 'twin.macro'

import Notification from 'components/Notification'
import Popover from 'components/Popover'
import { NotificationDataWithID } from 'types/notification'

export interface NotificationListProps {
  notifications: NotificationDataWithID[]
  referenceElement: HTMLElement | null
  onCloseNotification: (id: string) => void
}

export const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  referenceElement,
  onCloseNotification,
}) => {
  return !!referenceElement && notifications.length > 0 ? (
    <Popover
      referenceElement={referenceElement}
      placement="bottom"
      distance={7}
    >
      <div className="notifications-container" tw="flex flex-col gap-[7px]">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            message={notification.message}
            title={notification.title}
            type={notification.type}
            close={() => onCloseNotification(notification.id)}
          />
        ))}
      </div>
    </Popover>
  ) : null
}
