import React, { useEffect, useMemo } from 'react'

import { TalosDataResponse } from 'types/talos-data-response'

// TBD move `processResponse` and its registration to the socket
//   `processResponse` should update some atom identified by reqId

const makeProcessResponse = <DataType>(
  setLatestResponse: React.Dispatch<
    React.SetStateAction<TalosDataResponse<DataType>>
  >,
  reqId: number,
) => {
  return reqId == null
    ? null
    : (evt: MessageEvent): void => {
        try {
          // How many messages are parsed versus used?
          // TBD: a single parser and a map from reqId to atom
          const dataResponse = JSON.parse(
            evt.data,
          ) as TalosDataResponse<DataType>

          if (dataResponse.reqid !== reqId) {
            return
          }

          setLatestResponse(dataResponse)
        } catch (error) {
          console.error(error)
        }
      }
}

export const useResponseProcessor = <DataType>(
  socket: WebSocket,
  setLatestResponse: React.Dispatch<
    React.SetStateAction<TalosDataResponse<DataType>>
  >,
  reqId: React.RefObject<number>,
): void => {
  const processResponse = useMemo(
    () => makeProcessResponse(setLatestResponse, reqId.current),
    [setLatestResponse, reqId],
  )

  useEffect(() => {
    if (socket && processResponse) {
      socket.addEventListener('message', processResponse)

      return () => {
        socket.removeEventListener('message', processResponse)
      }
    }
  }, [socket, processResponse])
}
