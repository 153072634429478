import { useCallback, useEffect, useState } from 'react'

import useSecurities from 'hooks/useSecurities'
import { Currency } from 'types/currency'

// TBD: share this state; load only once when required, unless explicitly refreshed
// return a list of all supported currencies for trading
export const useSecurityCurrencies = (
  baseCurrency?: Currency, // if given a base currency return a list of quoted currency supported for trading
) => {
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const securitiesData = useSecurities()
  const [filteredCurrencies, setFilteredCurrencies] = useState<Currency[]>([])

  useEffect(() => {
    if (!baseCurrency && securitiesData && securitiesData.length > 0) {
      const newCurrencies: Currency[] = securitiesData.map(
        (security): Currency => {
          const symbol = security.BaseCurrency
          const match = security.Description.match(/(.*)[-\/]/i)

          const description = match[1]?.trim()

          return { Symbol: symbol, Description: description }
        },
      )

      const unique = [
        ...new Map(newCurrencies.map((item) => [item.Symbol, item])).values(),
      ]

      setCurrencies(unique)
      setFilteredCurrencies(unique)
    }
  }, [baseCurrency, securitiesData])

  useEffect(() => {
    if (baseCurrency && securitiesData && securitiesData.length > 0) {
      const securitiesOnBase = securitiesData.filter(
        (item) => item.BaseCurrency === baseCurrency.Symbol,
      )

      const newCurrencies: Currency[] = securitiesOnBase.map(
        (security): Currency => {
          const symbol = security.QuoteCurrency
          const match = security.Description.match(/(.*)[-\/](.*)/i)

          const description = match[2]?.trim()

          return { Symbol: symbol, Description: description }
        },
      )

      const unique = [
        ...new Map(newCurrencies.map((item) => [item.Symbol, item])).values(),
      ]

      setCurrencies(unique)
      setFilteredCurrencies(unique)
    }
  }, [baseCurrency, securitiesData])

  const searchCurrency = useCallback(
    (x: string) => {
      if (x.length === 0) {
        setFilteredCurrencies(currencies)
      } else {
        const filteredCurrencies = currencies.filter((currency) => {
          return (
            currency.Description.toLowerCase().includes(x.toLowerCase()) ||
            currency.Symbol.toLowerCase().includes(x.toLowerCase())
          )
        })
        setFilteredCurrencies(filteredCurrencies)
      }
    },
    [currencies],
  )

  const resetSearch = useCallback(() => {
    setFilteredCurrencies(currencies)
  }, [currencies])

  return { currencies, filteredCurrencies, searchCurrency, resetSearch }
}
