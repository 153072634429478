import { useDirection } from 'hooks/useDirection'
import 'twin.macro'

import {
  Wrapper,
  Divider,
  ArrowWrapper,
  ArrowSmall,
  ArrowBig,
  SpreadInfo,
} from './SpreadIndicator.styles'

export interface SpreadIndicatorProps {
  spread: string
  middlePrice: number
}

export const SpreadIndicator: React.FC<SpreadIndicatorProps> = ({
  spread = '00',
  middlePrice,
}) => {
  const [spreadFlashDirection] = useDirection(middlePrice)

  return (
    <Wrapper>
      <Divider />

      <ArrowWrapper show={spreadFlashDirection === 'up'}>
        <ArrowSmall />
        <ArrowBig />
      </ArrowWrapper>

      <SpreadInfo className="group">
        <span>{spread}</span>
        <span tw="ml-0.5 hidden group-hover:block">BPS</span>
      </SpreadInfo>

      <ArrowWrapper show={spreadFlashDirection === 'down'}>
        <ArrowBig />
        <ArrowSmall />
      </ArrowWrapper>
    </Wrapper>
  )
}
