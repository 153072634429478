import { useMemo } from 'react'

import { CurrencyPair } from 'types/currency-pair'

import useSecurities from './useSecurities'

export const useCanonicalSizeBuckets = (currencyPair: CurrencyPair) => {
  const securitiesData = useSecurities(currencyPair)

  return useMemo(() => {
    if (securitiesData && securitiesData.length > 0) {
      const firstItem = securitiesData[0]
      const sizeBuckets = firstItem.SizeBuckets.map((size) => size.Size)
      return sizeBuckets
    }
    return null
  }, [securitiesData])
}
