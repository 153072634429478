import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export interface IconButtonProps {
  size?: 'default' | 'small'
}

export const IconButton = styled.button<IconButtonProps>`
  ${tw`min-h-[40px] p-2.5 border-radius[4px] border border-color[transparent] font-bahnschrift font-semibold text-[14px] leading-[17px] text-gray-200`}
  ${tw`hover:bg-[rgb(55, 55, 55)] hover:text-white`}
  ${tw`focus:border focus:border-solid focus:border-gray-800`}
  ${tw`active:bg-black active:text-white active:border active:border-black`}

  & > svg {
    ${tw`w-[24px] fill-gray-800 `}
  }

  &:hover > svg {
    ${tw`fill-white `}
  }

  &:active > svg {
    ${tw`fill-white `}
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      ${tw`min-h-[unset] p-1 font-normal text-[12px] leading-[14px]`}

      & > svg {
        width: 16px;
      }
    `}
`
