import { useRef, useState, useMemo } from 'react'
import 'twin.macro'

import { Side } from 'lib/interfaces'
import { splitPrice } from 'lib/utils'

import {
  Button,
  TypeButtonTitleBuy,
  TypeButtonTitleSell,
  PriceInteger,
  PriceDecimal,
  TypeButtonTitle,
} from './PriceButton.styles'

export interface PriceButtonProps {
  type: Side
  price: string
  onSelect?: () => void
  onConfirm?: () => void
}

export const PriceButton: React.FC<PriceButtonProps> = ({
  type,
  price,
  onSelect,
  onConfirm,
}) => {
  const formattedPrice =
    isNaN(Number(price)) || !price ? '-' : Number(price).toString()

  const [confirmActive, setConfirmActive] = useState<boolean>(false)

  const buttonEl = useRef<HTMLButtonElement | null>(null)

  const typeButtonTitle = type === 'buy' ? 'Buy' : 'Sell'

  const [priceInteger, priceDecimal] = splitPrice(formattedPrice)

  const handleOnClick = () => {
    if (confirmActive) {
      onConfirm && onConfirm()
      if (buttonEl) buttonEl.current.blur()
    } else {
      onSelect && onSelect()
      setConfirmActive(true)
    }
  }

  const TypeButtonTitleBuySell = useMemo(
    () => (type === 'buy' ? TypeButtonTitleBuy : TypeButtonTitleSell),
    [type],
  )

  return (
    <Button
      typeButton={type}
      onBlur={() => {
        setConfirmActive(false)
      }}
      onClick={handleOnClick}
      className="group"
      aria-label={type}
      ref={buttonEl}
    >
      {confirmActive ? (
        <TypeButtonTitle>Confirm {typeButtonTitle}</TypeButtonTitle>
      ) : (
        <TypeButtonTitleBuySell>{typeButtonTitle}</TypeButtonTitleBuySell>
      )}

      <PriceInteger>{priceInteger}</PriceInteger>

      <PriceDecimal>
        {'.'}
        {priceDecimal}
      </PriceDecimal>
    </Button>
  )
}
