/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useCallback, useMemo, useState } from 'react'
import 'twin.macro'

import Clock from 'assets/icons/clock.svg'
import Hamburger from 'assets/icons/hamburger.svg'
import IconButton from 'components/IconButton'
import { DateInputRow } from 'components/InputRow'
import NotificationList from 'components/NotificationList'
import { useTradeExecutor } from 'hooks/trade/useTradeExecutor'
import { useCcyPair } from 'hooks/useCcyPair/useCcyPair'
import { useNotifications } from 'hooks/useNotifications'
import { ClipIntervalType, OrderType } from 'lib/interfaces'
import { MarketDataSnapshot } from 'types/api/market-data-snapshot'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'

import ClipInterval from './ClipInterval'
import Header from './Header'
import InputPrice from './InputPrice'
import InputVariance from './InputVariance'
import Prices from './Prices'
import { NotificationsAnchor, Wrapper } from './StreamingTile.styles'
import TradeAmount from './TradeAmount'
import VolumeLadder from './VolumeLadder'

export interface StreamingTileProps {
  currencyPair: CurrencyPair
  marketDataSnapshot: MarketDataSnapshot
  sizeBuckets: string[]
  setSizeBuckets: (sb: string[]) => void
  amount: string
  setAmount: (amt: string) => void
  normalSize: string
  setNormalSize: (normalSize: string) => void
  onBaseCurrencyChange?: (value: Currency) => void
  onQuotedCurrencyChange?: (value: Currency) => void
}

export const StreamingTile: React.FC<StreamingTileProps> = ({
  currencyPair,
  marketDataSnapshot,
  sizeBuckets,
  setSizeBuckets,
  amount,
  setAmount,
  normalSize,
  setNormalSize,
  onBaseCurrencyChange,
  onQuotedCurrencyChange,
}) => {
  const [orderType, setOrderType] = useState<OrderType>('market')

  const [showLadder, setShowLadder] = useState<boolean>(false)
  const [showStartTime, setShowStartTime] = useState<boolean>(false)

  const [amountError, setAmountError] = useState<string | null>(null)
  const [tradeAmountCurrency, setTradeAmountCurrency] = useState<Currency>(
    currencyPair[0],
  )

  // TBD use amount as-is if tradeAmountCurrency.Symbol === baseCurrencySymbol
  //   else look up 'amount' in the 'quotedCurrency' volume ladder data

  const {
    buyPrice,
    sellPrice,
    spread,
    maxAmountAvailableBase,
    maxAmountAvailableQuoted,
    resetPrices,
  } = useCcyPair(currencyPair, marketDataSnapshot)

  const reset = useCallback(() => {
    resetPrices()
    setAmount('')
  }, [resetPrices, setAmount])

  const [limitPrice, setLimitPrice] = useState<string>('')
  const [stopPrice, setStopPrice] = useState<string>('')

  const [clipSize, setClipSize] = useState<string>('')
  const [clipSizeError, setClipSizeError] = useState<string | null>(null)

  const [clipIntervalPeriod, setClipIntervalPeriod] =
    useState<ClipIntervalType>('seconds')
  const [clipIntervalAmount, setClipIntervalAmount] = useState<string>('')

  const [variance, setVariance] = useState<string>('')

  const [startTime, setStartTime] = useState<Date | null>(null)
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [tradeCurrency, setTradeCurrency] = useState<Currency>(currencyPair[0])

  const makeTrade = useTradeExecutor(
    orderType,
    currencyPair,
    amount,
    tradeCurrency,
    startTime,
    endTime,
    limitPrice.replaceAll(',', ''),
    stopPrice.replaceAll(',', ''),
    variance,
    clipSize,
    clipIntervalPeriod,
    clipIntervalAmount,
  )

  const [anchorReferenceElement, setAnchorReferenceElement] =
    useState<HTMLDivElement | null>(null)

  const {
    add: addNotification,
    remove: removeNotification,
    notifications,
  } = useNotifications()

  const sell = useCallback(() => makeTrade('Sell'), [makeTrade])
  const buy = useCallback(() => makeTrade('Buy'), [makeTrade])

  const handleOnHeaderRFQClick = () => {
    console.log('handleOnHeaderRFQClick')
  }

  const [handleOnBaseCurrencyChange, handleOnQuotedCurrencyChange] = useMemo(
    () => [
      (value: Currency) => {
        const symbol = value?.Symbol
        const baseCurrencySymbol = currencyPair?.[0]?.Symbol

        if (baseCurrencySymbol !== symbol) {
          reset()
          onBaseCurrencyChange(value)
        }
      },
      (value: Currency) => {
        const symbol = value?.Symbol
        const quotedCurrencySymbol = currencyPair?.[1]?.Symbol

        if (quotedCurrencySymbol !== symbol) {
          reset()
          onQuotedCurrencyChange(value)
        }
      },
    ],
    [currencyPair, onBaseCurrencyChange, onQuotedCurrencyChange, reset],
  )

  const handleClipSizeChange = useCallback(
    (value: string) => {
      // const value = e?.target.value
      if (Number(value) <= Number(amount)) {
        setClipSize(value)
        setClipSizeError(null)
      } else {
        setClipSizeError('Clip size must be less than the trade amount value')
      }
    },
    [amount, setClipSize, setClipSizeError],
  )

  const handleClipIntervalChange = (
    input: string,
    clipIntervalPeriod: ClipIntervalType,
  ) => {
    setClipIntervalAmount(input)
    setClipIntervalPeriod(clipIntervalPeriod)
  }

  const handleVarianceChange = (value: string) => {
    setVariance(value)
  }

  const [baseCurrency, quotedCurrency] = currencyPair

  return (
    <Wrapper>
      <NotificationsAnchor ref={setAnchorReferenceElement} />
      <Header
        baseCurrency={baseCurrency}
        quotedCurrency={quotedCurrency}
        orderStatus={orderType}
        onOrderChange={setOrderType}
        onRFQClick={handleOnHeaderRFQClick}
        onBaseCurrencyChange={(e) => handleOnBaseCurrencyChange(e.value)}
        onQuotedCurrencyChange={(e) => handleOnQuotedCurrencyChange(e.value)}
      />
      <Prices
        onBuyConfirm={buy}
        onSellConfirm={sell}
        buyPrice={buyPrice}
        sellPrice={sellPrice}
        spread={spread}
      />

      <TradeAmount
        baseCurrency={baseCurrency}
        quotedCurrency={quotedCurrency}
        maxAvailableBase={maxAmountAvailableBase}
        maxAvailableQuoted={maxAmountAvailableQuoted}
        amount={amount}
        onAmountChange={setAmount}
        onCurrencyChange={setTradeAmountCurrency}
        error={amountError}
      />
      {orderType === 'limit' ||
      orderType === 'stop-limit' ||
      orderType === 'steady-pace' ? (
        <InputPrice
          placeholder="Limit Price"
          value={limitPrice}
          onChange={setLimitPrice}
          currency={quotedCurrency}
        />
      ) : null}

      {orderType === 'stop-limit' ? (
        <InputPrice
          placeholder="Stop Price"
          value={stopPrice}
          onChange={setStopPrice}
          currency={quotedCurrency}
        />
      ) : null}

      {orderType === 'steady-pace' ? (
        <InputPrice
          placeholder="Clip Size"
          value={clipSize}
          onChange={handleClipSizeChange}
          currency={baseCurrency}
          error={clipSizeError}
        />
      ) : null}

      {orderType === 'steady-pace' ? (
        <ClipInterval
          value={clipIntervalAmount}
          clipIntervalPeriod={clipIntervalPeriod}
          onChange={handleClipIntervalChange}
        />
      ) : null}

      {orderType === 'steady-pace' ? (
        <InputVariance value={variance} onChange={handleVarianceChange} />
      ) : null}

      {showStartTime && (
        <Fragment>
          <DateInputRow date={startTime} setDate={setStartTime} type="start" />
          <DateInputRow date={endTime} setDate={setEndTime} type="stop" />
        </Fragment>
      )}

      <div tw="flex justify-between">
        <IconButton size="small" onClick={() => setShowLadder(!showLadder)}>
          <Hamburger style={showLadder ? { fill: 'white' } : {}} />
        </IconButton>
        <IconButton
          data-testid="clock-button"
          size="small"
          onClick={() => setShowStartTime(!showStartTime)}
        >
          <Clock style={showStartTime ? { fill: 'white' } : {}} />
        </IconButton>
      </div>

      {showLadder && (
        <VolumeLadder
          currencyPair={currencyPair}
          marketDataSnapshot={marketDataSnapshot}
          sizeBuckets={sizeBuckets}
          setSizeBuckets={setSizeBuckets}
        />
      )}

      <NotificationList
        notifications={notifications}
        onCloseNotification={removeNotification}
        referenceElement={anchorReferenceElement}
      />
    </Wrapper>
  )
}
