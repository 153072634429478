/* eslint-disable import/order */
import { useCallback } from 'react'

import { useSocket } from 'hooks/useSocket'
import { OrderType, ClipIntervalType } from 'lib/interfaces'
import {
  createLimitOrderMessage,
  createMarketOrderMessage,
  createSteadyPaceOrderMessage,
  createStopLimitOrderMessage,
} from 'lib/trades'

import { OrderParameters } from 'types/api/new-order-single'
import { NewOrderSingleMessage } from 'types/api/new-order-single'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'

import { parseInterval } from 'lib/utils/interval'

export const useTradeExecutor = (
  orderType: OrderType,
  pair: CurrencyPair,
  qty: string,
  currency: Currency,
  startTime: Date | null,
  endTime: Date | null,
  price: string,
  stopLimit: string,
  variance: string,
  clipSize: string,
  clipIntervalPeriod: ClipIntervalType,
  clipIntervalAmount: string,
) => {
  const socket = useSocket()

  const makeTrade = useCallback(
    (side: 'Buy' | 'Sell') => {
      const makeOrder = (message: NewOrderSingleMessage) => {
        if (socket.readyState === socket.OPEN) {
          socket.send(JSON.stringify(message))
        } else {
          socket.addEventListener('open', () =>
            socket.send(JSON.stringify(message)),
          )
        }
      }
      const params: OrderParameters = {}

      if (startTime) params.StartTime = startTime.toISOString()
      if (endTime) params.EndTime = endTime.toISOString()

      if (orderType === 'market') {
        const message = createMarketOrderMessage(
          pair,
          side,
          qty,
          currency,
          params,
        )
        makeOrder(message)
      } else if (orderType === 'limit') {
        const message = createLimitOrderMessage(
          pair,
          side,
          qty,
          currency,
          price,
          params,
        )
        makeOrder(message)
      } else if (orderType === 'stop-limit') {
        Object.assign(params, {
          TriggerPrice: stopLimit,
        })
        const message = createStopLimitOrderMessage(
          pair,
          side,
          qty,
          currency,
          price,
          params,
        )
        makeOrder(message)
      } else if (orderType === 'steady-pace') {
        Object.assign(params, {
          Variance: variance,
          ClipSize: clipSize,
          ClipInterval: parseInterval(clipIntervalPeriod, clipIntervalAmount),
        })
        const message = createSteadyPaceOrderMessage(
          pair,
          side,
          qty,
          currency,
          params,
        )
        makeOrder(message)
      }
    },
    [
      orderType,
      pair,
      qty,
      startTime,
      endTime,
      socket,
      currency,
      clipIntervalAmount,
      clipIntervalPeriod,
      clipSize,
      price,
      stopLimit,
      variance,
    ],
  )

  return makeTrade
}
