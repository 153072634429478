import { useEffect, useMemo, useState } from 'react'

import useBalance from 'hooks/useBalance'
import { calculateSpread } from 'lib/utils'
import { MarketDataSnapshot } from 'types/api/market-data-snapshot'
import { CurrencyPair } from 'types/currency-pair'

// could use more factoring

export const useCcyPair = (
  currencyPair: CurrencyPair,
  marketDataSnapshot: MarketDataSnapshot,
) => {
  const [sellPrice, setSellPrice] = useState<string>('-')
  const [buyPrice, setBuyPrice] = useState<string>('-')
  const [spread, setSpread] = useState<string>('-')
  const [maxAmountAvailableBase, setMaxAmountAvailableBase] =
    useState<string>('0.00')
  const [maxAmountAvailableQuoted, setMaxAmountAvailableQuoted] =
    useState<string>('0.00')

  const [baseCurrency, quotedCurrency] = useMemo(
    () => currencyPair,
    [currencyPair],
  )

  const balanceSymbols = useMemo(
    () => [baseCurrency.Symbol, quotedCurrency.Symbol],
    [baseCurrency.Symbol, quotedCurrency.Symbol],
  )

  const balances = useBalance(balanceSymbols)

  useEffect(() => {
    // reset the max available amounts when the currencyPair changes
    if (currencyPair) {
      resetBalanceData()
    }
  }, [currencyPair])

  useEffect(() => {
    if (balances && balances.length > 0) {
      const balanceDataBase = balances.find(
        (balance) => balance.Currency === currencyPair[0].Symbol,
      )

      const balanceDataQuoted = balances.find(
        (balance) => balance.Currency === currencyPair[1].Symbol,
      )

      if (balanceDataBase) {
        setMaxAmountAvailableBase(
          balanceDataBase.AvailableAmount
            ? balanceDataBase.AvailableAmount
            : balanceDataBase.Amount,
        )
      }

      if (balanceDataQuoted) {
        setMaxAmountAvailableQuoted(
          balanceDataQuoted.AvailableAmount
            ? balanceDataQuoted.AvailableAmount
            : balanceDataQuoted.Amount,
        )
      }
    }
  }, [balances, currencyPair])

  useEffect(() => {
    if (marketDataSnapshot) {
      const bids = marketDataSnapshot.Bids
      const offers = marketDataSnapshot.Offers

      if (bids.length > 0 && offers.length > 0) {
        const newBuyPrice = offers[0].Price
        const newSellPrice = bids[0].Price
        const newSpread = calculateSpread(
          Number(newBuyPrice),
          Number(newSellPrice),
        )
        setBuyPrice(newBuyPrice)
        setSellPrice(newSellPrice)
        setSpread(newSpread.toFixed(2).toString())
      } else {
        resetPrices()
      }
    } else {
      resetPrices()
    }
  }, [marketDataSnapshot])

  const resetPrices = () => {
    setBuyPrice('-')
    setSellPrice('-')
    setSpread('-')
  }

  const resetBalanceData = () => {
    setMaxAmountAvailableBase('0.00')
    setMaxAmountAvailableQuoted('0.00')
  }

  return {
    buyPrice,
    sellPrice,
    spread,
    maxAmountAvailableBase,
    maxAmountAvailableQuoted,
    resetPrices,
  }
}
