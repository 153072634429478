import { atom, RecoilState, selector } from 'recoil'

import { PricingSimDiagnostics } from 'types/pricing-sim-diagnostics'

type ParamsSlice = {
  interval: RecoilState<number>
  loadingFactor: RecoilState<number>
  loadingPercent: RecoilState<number>
}

type DiagsSlice = {
  busyDt: RecoilState<number>
  skewDt: RecoilState<number>
  all: RecoilState<PricingSimDiagnostics>
}

export type PricingSimulationSlice = {
  parameters: ParamsSlice
  diagnostics: DiagsSlice
}

const loadingFactor = atom<number>({
  key: 'pricingSim.params.loadingFactor',
  default: 0.1,
})

const parameters = {
  interval: atom<number>({
    key: 'pricingSim.params.interval',
    default: 250,
  }),
  loadingFactor,
  loadingPercent: selector<number>({
    key: 'pricingSim.params.loadingPercent',
    get: ({ get }) => Math.round(100 * get(loadingFactor)),
    set: ({ set }, nextLoadingPercent: number) => {
      set(loadingFactor, nextLoadingPercent / 100)
    },
  }),
}

const diagnostics = {
  busyDt: atom<number>({
    key: 'pricingSim.diags.busyDt',
    default: 0,
  }),
  skewDt: atom<number>({
    key: 'pricingSim.diags.skewDt',
    default: 0,
  }),
  all: null,
}

diagnostics.all = selector<PricingSimDiagnostics>({
  key: 'pricingSim.diags.all',
  get: ({ get }) => ({
    busyDt: get(diagnostics.busyDt),
    skewDt: get(diagnostics.skewDt),
  }),
  set: ({ set }, nextPricingSimDiagnostics: PricingSimDiagnostics) => {
    set(diagnostics.busyDt, nextPricingSimDiagnostics.busyDt)
    set(diagnostics.skewDt, nextPricingSimDiagnostics.skewDt)
  },
})

export const pricingSimulation: PricingSimulationSlice = {
  parameters,
  diagnostics,
}
