import { useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import 'twin.macro'

export interface PopoverProps {
  referenceElement: HTMLElement | null
  placement:
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | 'auto'
    | 'auto-start'
    | 'auto-end'
  distance: number //px
  children?: React.ReactNode
}

export const Popover: React.FC<PopoverProps> = ({
  referenceElement,
  placement,
  distance,
  children,
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    // add px of distance from distance prop
    modifiers: [{ name: 'offset', options: { offset: [0, distance] } }],
  })

  return ReactDOM.createPortal(
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      tw="z-50"
    >
      {children}
    </div>,
    document.body,
  )
}
