// insert here global utils functions

// TBD: take the next number in sequence from a counter living with the socket
export const createReqId = () => Math.floor(Math.random() * Math.pow(10, 4))

export const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d)\.)/g, ',')
}

export const range = (n: number): number[] =>
  new Array(n).fill(undefined).map((_, idx) => idx)

export const splitPrice = (price: string) => {
  const [integer, decimal] = price.split('.')
  const integerWithComma = numberWithCommas(integer)

  return [
    integerWithComma.length > 0 ? integerWithComma : '0',
    decimal && decimal.length > 0 ? decimal : '00',
  ]
}

export const calculateSpread = (ask: number, bid: number) =>
  ((ask - bid) / ((ask + bid) / 2)) * 10000

export const clampSizeBuckets = (
  sizeBuckets: string[],
  normalSize: string,
): string[] => {
  if (sizeBuckets.length < 2) return sizeBuckets

  const newSizeBuckets = [...sizeBuckets]

  let maxBucketSize = newSizeBuckets[0]

  for (let i = 0; i < newSizeBuckets.length; i++) {
    if (newSizeBuckets[i].trim().length === 0) {
      newSizeBuckets[i] = normalSize
    }

    if (Number(newSizeBuckets[i]) < Number(maxBucketSize)) {
      newSizeBuckets[i] = maxBucketSize
    } else {
      maxBucketSize = newSizeBuckets[i]
    }
  }

  return newSizeBuckets
}

export const numericStringHasValue = (s: string) => s && s.trim().length > 0
