import { FC } from 'react'
import tw from 'twin.macro'

import ArrowLeft from 'assets/icons/arrow-left.svg'
import ArrowRight from 'assets/icons/arrow-right.svg'
import { monthNames } from 'lib/constants'

const Container = tw.div`flex justify-between p-2`
const ArrowButton = tw.button`text-white`
const HeaderText = tw.button`text-gray-200 text-[16px] font-bahnschrift`
const HeaderContainer = tw.div`flex gap-2`

const getMonthName = (date: Date) => monthNames[date.getMonth()]

export interface DatePickerHeaderProps {
  date: Date
  increaseMonth(): void
  decreaseMonth(): void
  showMonthPicker: boolean
  toggleMonthPicker(): void
}

export const DatePickerHeader: FC<DatePickerHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  showMonthPicker,
  toggleMonthPicker,
}) => {
  return (
    <Container>
      <ArrowButton
        data-testid="left-arrow-button"
        onClick={() => decreaseMonth()}
      >
        <ArrowLeft tw="h-[16px] w-[16px]" />
      </ArrowButton>
      <HeaderContainer>
        {!showMonthPicker && (
          <HeaderText onClick={toggleMonthPicker}>
            {getMonthName(date)}
          </HeaderText>
        )}

        <HeaderText>{date.getFullYear()}</HeaderText>
      </HeaderContainer>
      <ArrowButton
        data-testid="right-arrow-button"
        onClick={() => increaseMonth()}
      >
        <ArrowRight tw="h-[16px] w-[16px]" />
      </ArrowButton>
    </Container>
  )
}
