import { useMemo } from 'react'

import { CurrencyPair } from 'types/currency-pair'

import useSecurities from './useSecurities'

export const useNormalSize = (currencyPair: CurrencyPair) => {
  const securitiesData = useSecurities(currencyPair)

  return useMemo(() => {
    if (securitiesData && securitiesData.length > 0) {
      const firstItem = securitiesData[0]
      return firstItem.NormalSize
    }
    return null
  }, [securitiesData])
}
