import tw from 'twin.macro'

import Input from 'components/Input'
import ListItem from 'components/ListItem'

export const InputItem = tw(Input)`
    pr-7
    border-bottom-left-radius[0px]
    border-bottom-right-radius[0px]
    border-0
    hover:border-0
    focus:border-0
`

export const NotFoundItem = (
  <ListItem role="option" tabIndex={0} disabled={true}>
    Currencies not found
  </ListItem>
)
