import 'twin.macro'

import WarningIcon from 'assets/icons/alert-octagon.svg'
import SuccessIcon from 'assets/icons/check-circle.svg'
import ErrorIcon from 'assets/icons/error.svg'
import QuestionIcon from 'assets/icons/help-circle.svg'
import DeleteIcon from 'assets/icons/trash.svg'
import { useTimeout } from 'hooks/useTimeout'
import { NotificationData, NotificationType } from 'types/notification'

import { Wrapper, InfoContainer, Title, Message } from './Notification.styles'

const Icon: React.FC<{ type: NotificationType }> = ({ type }) =>
  'success' === type ? (
    <SuccessIcon data-testid="success-icon" tw="w-[24px] fill-brand-green" />
  ) : 'warning' === type ? (
    <WarningIcon data-testid="warning-icon" tw="w-[24px] fill-yellow" />
  ) : 'error' === type ? (
    <ErrorIcon data-testid="error-icon" tw="w-[24px] fill-brand-red" />
  ) : 'question' === type ? (
    <QuestionIcon
      data-testid="question-icon"
      tw="w-[24px] stroke-white fill-transparent"
    />
  ) : 'delete' === type ? (
    <DeleteIcon data-testid="delete-icon" tw="w-[24px] fill-brand-red" />
  ) : null

export interface NotificationProps extends NotificationData {
  close: () => void
}

export const Notification: React.FC<NotificationProps> = ({
  type,
  title,
  message,
  close,
}) => {
  useTimeout(close, 3000)

  return (
    <Wrapper data-testid="notification" onClick={close}>
      <Icon type={type} />
      <InfoContainer>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </InfoContainer>
    </Wrapper>
  )
}
