import React from 'react'
import 'twin.macro'

import Search from 'assets/icons/search.svg'
import List from 'components/List'
import ListItem from 'components/ListItem'
import useSecurityCurrencies from 'hooks/useSecurityCurrencies'
import { Currency } from 'types/currency'

import { InputItem, NotFoundItem } from './CurrencySelect.styles'

export interface CurrencySelectProps {
  baseCurrency?: Currency // this allow to shows the quoted currencies based on the base currency
  onSelectValue: (e: { value: Currency }) => void
}

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  baseCurrency,
  onSelectValue,
}) => {
  const { filteredCurrencies, searchCurrency } =
    useSecurityCurrencies(baseCurrency)

  // Event handler for keydowns
  const handleKeyDown = (value: Currency) => (e) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault()
        onSelectValue({ value: value })
        break
      default:
        break
    }
  }

  const Item = (currency: Currency) => (
    <ListItem
      id={currency.Symbol}
      role="option"
      tabIndex={0}
      key={currency.Symbol}
      onKeyDown={handleKeyDown(currency)}
      onClick={() => {
        onSelectValue({ value: currency })
      }}
    >
      <span>{currency.Description}</span>
      <span tw="font-bahnschrift font-normal text-[12px] leading-[14px] opacity-50">
        {currency.Symbol}
      </span>
    </ListItem>
  )

  const ListItems = () => {
    if (filteredCurrencies.length > 0) {
      return filteredCurrencies.map((item) => Item(item))
    }
    return NotFoundItem
  }

  return (
    <div tw="relative max-w-min">
      <List role="listbox" tabIndex={-1}>
        <li tw="relative">
          <InputItem
            aria-label="search-box"
            placeholder="Search"
            type="text"
            onChange={(e) => searchCurrency(e.target.value)}
          />
          <Search tw="w-4 h-full absolute right-[8px] top-0 bottom-0 fill-gray-800" />
        </li>
        {ListItems()}
      </List>
    </div>
  )
}
