import { FC } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
// gap-x-[38.75px] gap-y-[27px]
const Container = tw.div`absolute w-full h-[230px] top-[100px] left-0 bg-gray-700`
const Grid = tw.div`grid grid-cols-4 gap-y-0  justify-center`
const Cell = styled.button`
  ${tw`hover:bg-white hover:text-gray-900 h-[26px] w-[60px] font-bahnschrift text-[12px] text-gray-200 rounded-[4px]`}
`

interface GridPopOverProps {
  items: string[]
  handleClick(item: string, index: number): void
}

export const GridPopOver: FC<GridPopOverProps> = ({ items, handleClick }) => {
  return (
    <Container>
      <Grid>
        {items.map((item, index) => (
          <Cell key={item} onClick={() => handleClick(item, index)}>
            {item}
          </Cell>
        ))}
      </Grid>
    </Container>
  )
}
