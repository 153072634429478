import React, { useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import StreamingTile from 'components/StreamingTile'
import { useEphemeralSubscriptionData } from 'hooks/useEphemeralSubscriptionData'
import {
  replaceBaseCurrency,
  replaceQuotedCurrency,
  replaceAmount,
  replaceNormalSize,
  replaceSizeBuckets,
} from 'lib/reducers/streamingTileLocalSliverReducers'
import identify from 'lib/utils/identify'
import store from 'store'
import { Currency } from 'types/currency'
import { GroupPosition } from 'types/group'

import { Container } from './StreamingTileSlotConnector.styles'

// TBD:
//   * <StreamingTile currencyPairId={currencyPairId} />
//   * const UnspecifiedCurrencySymbol = '?'
//   * add-tile: push into store.streamingTiles.selectedGroup '?-?'
//   * set-tile-id:
//     * update store.streamingTiles.lookupByPosition(selectedGroup, last) 'BTC-?'
//     * update store.streamingTiles.lookupByPosition(selectedGroup, last) 'BTC-USDC'
//       * maybe subscribe
//   * unset-tile-id: (if required)
//     * update store.streamingTiles.lookupByPosition(selectedGroup, last) '?-USDC'
//       * maybe unsubscribe

export type StreamingTileSlotConnectorProps = GroupPosition

const StreamingTileSlotConnector: React.FC<GroupPosition> = (groupPosition) => {
  const [streamingTileLocalSliver, setStreamingTileLocalSliver] =
    useRecoilState(store.streamingTiles.lookupByPosition(groupPosition))

  const monId = useMemo(
    () =>
      streamingTileLocalSliver
        ? identify.streamingTileLocalSliver(streamingTileLocalSliver)
        : null,
    [streamingTileLocalSliver],
  )

  const marketDataSnapshot = useRecoilValue(store.marketDataSnapshots(monId))

  const [
    setStreamingTileBaseCurrency,
    setStreamingTileQuotedCurrency,
    setAmount,
    setNormalSize,
    setSizeBuckets,
  ] = useMemo(
    () => [
      (baseCurrency: Currency) => {
        setStreamingTileLocalSliver(replaceBaseCurrency(baseCurrency))
      },
      (quotedCurrency: Currency) => {
        setStreamingTileLocalSliver(replaceQuotedCurrency(quotedCurrency))
      },
      (amount: string) => {
        setStreamingTileLocalSliver(replaceAmount(amount))
      },
      (normalSize: string) => {
        setStreamingTileLocalSliver(replaceNormalSize(normalSize))
      },
      (sizeBuckets: string[]) => {
        setStreamingTileLocalSliver(replaceSizeBuckets(sizeBuckets))
      },
    ],
    [setStreamingTileLocalSliver],
  )

  const { currencyPair, amount, normalSize, sizeBuckets } =
    streamingTileLocalSliver ?? {}

  useEphemeralSubscriptionData(currencyPair, setNormalSize, setSizeBuckets) // TBD delete this

  return (
    <Container>
      {streamingTileLocalSliver && (
        <StreamingTile
          currencyPair={currencyPair}
          marketDataSnapshot={marketDataSnapshot}
          amount={amount}
          setAmount={setAmount}
          normalSize={normalSize}
          setNormalSize={setNormalSize}
          sizeBuckets={sizeBuckets}
          setSizeBuckets={setSizeBuckets}
          onBaseCurrencyChange={setStreamingTileBaseCurrency}
          onQuotedCurrencyChange={setStreamingTileQuotedCurrency}
        />
      )}
    </Container>
  )
}

export default StreamingTileSlotConnector
