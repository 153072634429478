import tw from 'twin.macro'

export const Container = tw.div`
  font-bahnschrift
  grid
  grid-cols-4
  text-[12px]
  justify-items-stretch
  h-fc
`

const CellHeader = tw.div`text-gray-500 h-[22px]`
export const QTYCellHeader = tw(CellHeader)`justify-self-start`
export const SellCellHeader = tw(CellHeader)`justify-self-start`
export const SpreadCellHeader = tw(CellHeader)`justify-self-center`
export const BuyCellHeader = tw(CellHeader)`justify-self-end`

const Cell = tw.div`flex items-center h-[30px]`
export const QTYCell = tw(Cell)`justify-center items-center px-[3.4px] `
export const BuyCell = tw(Cell)`justify-end text-brand-light pr-1`
export const SellCell = tw(Cell)`text-brand-light pl-1`
export const SpreadCell = tw(Cell)`
  justify-center
  text-gray-200
`
