/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo } from 'react'

import { useSocket } from 'hooks/useSocket'
import { useSubscription } from 'hooks/useSubscription'
import identify from 'lib/utils/identify'
import {
  SecurityCustomerParameters,
  SecurityCustomerResponse,
} from 'types/api/security'
import { CurrencyPair } from 'types/currency-pair'

// TBD: share this state; load only once when required, unless explicitly refreshed
export const useSecurities = (
  currencyPair?: CurrencyPair,
): SecurityCustomerResponse[] | null => {
  const socket = useSocket()

  const securityParameters: SecurityCustomerParameters = useMemo(() => {
    const Symbols = currencyPair ? [identify.currencyPair(currencyPair)] : []

    return {
      name: 'Security',
      Symbols,
    }
  }, [currencyPair])

  const securities = useSubscription<SecurityCustomerResponse>(
    socket,
    securityParameters,
  )

  return securities
}
