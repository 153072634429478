import 'twin.macro'

import StreamingPriceButton from 'components/StreamingTile/PriceButton'
import StreamingSpreadIndicator from 'components/StreamingTile/SpreadIndicator'

import { Wrapper } from './Prices.styles'

export interface PricesProps {
  sellPrice: string
  buyPrice: string
  spread: string
  onBuyConfirm?: () => void
  onSellConfirm?: () => void
}

export const Prices: React.FC<PricesProps> = ({
  sellPrice = '0.00',
  buyPrice = '0.00',
  spread = '0',
  onBuyConfirm,
  onSellConfirm,
}) => {
  const middlePrice = (Number(buyPrice) + Number(sellPrice)) / 2
  return (
    <Wrapper>
      <StreamingPriceButton
        type="sell"
        price={sellPrice}
        onConfirm={() => onSellConfirm && onSellConfirm()}
      />
      <StreamingSpreadIndicator spread={spread} middlePrice={middlePrice} />
      <StreamingPriceButton
        type="buy"
        price={buyPrice}
        onConfirm={() => onBuyConfirm && onBuyConfirm()}
      />
    </Wrapper>
  )
}
