import styled from 'styled-components'
import tw from 'twin.macro'
export interface ButtonProps {
  size?: 'default' | 'small'
}
export const Button = styled.button<ButtonProps>`
  ${tw`
    min-h-[40px]
    py-2
    px-4
    border-radius[4px]
    border
    border-color[transparent]
    font-bahnschrift
    font-semibold
    text-[14px]
    leading-[17px]
    text-gray-200
    flex
    items-center
  `}
  ${({ size }) =>
    size === 'small'
      ? tw`min-h-[unset] py-[3px] px-2 font-normal text-[12px] leading-[14px]`
      : ''}
  ${tw`hover:bg-[rgb(55, 55, 55)] hover:text-white`}
  ${tw`focus:border focus:border-solid focus:border-gray-800`}
  ${tw`active:bg-black active:text-white active:border active:border-black`}
`
