import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useRedirectToLogin } from 'hooks/useRedirectToLogin'

interface SocketContextType {
  socket: WebSocket
  getNextReqId: (monId: string) => number
  getMonReqId: (monId: string) => number
  getReqMonId: (reqId: number) => string
  isConnected: boolean
}

const defaultSocketContextValue = {
  socket: null,
  getNextReqId: () => -1,
  getMonReqId: () => -1,
  getReqMonId: () => '',
  isConnected: false,
}

export const SocketContext = createContext<SocketContextType>(
  defaultSocketContextValue,
)

export type SocketContextProviderProps = {
  children?: JSX.Element | JSX.Element[]
  loginUrl: string
  url: string
}

export const SocketContextProvider: React.FC<SocketContextProviderProps> = ({
  children,
  loginUrl,
  url,
}) => {
  const reqIdRef = useRef(0)
  const monIdsToReqIdsRef = useRef(new Map<string, number>())
  const reqIdsToMonIdsRef = useRef(new Map<number, string>())

  const [socket, setSocket] = useState<WebSocket>()
  const [isConnected, setIsConnected] = useState<boolean>(false)

  const getNextReqId = useCallback(
    (monId: string) => {
      const nextReqId = ++reqIdRef.current
      monIdsToReqIdsRef.current.set(monId, nextReqId)
      reqIdsToMonIdsRef.current.set(nextReqId, monId)
      return nextReqId
    },
    [monIdsToReqIdsRef, reqIdsToMonIdsRef, reqIdRef],
  )

  const getMonReqId = useCallback(
    (monId: string) => monIdsToReqIdsRef.current.get(monId),
    [monIdsToReqIdsRef],
  )

  const getReqMonId = useCallback(
    (reqId: number) => reqIdsToMonIdsRef.current.get(reqId),
    [reqIdsToMonIdsRef],
  )

  const [onOpen, onClose] = useMemo(
    () => [
      () => {
        setIsConnected(true)
      },
      () => {
        setIsConnected(false)
      },
    ],
    [setIsConnected],
  )

  const redirectToLogin = useRedirectToLogin(loginUrl)

  const onError = useCallback(
    (err) => {
      console.log('socket onError', err)
      redirectToLogin()
    },
    [redirectToLogin],
  )

  useEffect(() => {
    let socket: WebSocket

    try {
      socket = new WebSocket(url)
    } catch (err) {
      console.log('connection error (1)', err)
    }

    if (socket) {
      socket.addEventListener('open', onOpen)
      socket.addEventListener('close', onClose)
      socket.addEventListener('error', onError)

      setSocket(socket)

      return () => {
        try {
          socket.removeEventListener('open', onOpen)
          socket.removeEventListener('close', onClose)
          socket.removeEventListener('error', onError)

          socket.close()
        } catch (err) {
          console.log('socket error on cleanup (2)', err)
        }
      }
    }
  }, [url, onOpen, onClose, onError])

  const socketContext = useMemo<SocketContextType>(
    () => ({
      socket,
      getNextReqId,
      getMonReqId,
      getReqMonId,
      isConnected,
    }),
    [socket, getNextReqId, getMonReqId, getReqMonId, isConnected],
  )

  return (
    <SocketContext.Provider value={socketContext}>
      {children}
    </SocketContext.Provider>
  )
}
