import { StreamParameters } from 'types/stream-parameters'

import { useTalosSubscription } from './useTalosSubscription'

export const useSubscription = <DataType>(
  socket: WebSocket,
  stream: StreamParameters,
): DataType[] => {
  const dataResponse = useTalosSubscription<DataType[]>(socket, stream)

  if (!dataResponse) return []

  const dataAttribute: DataType[] = dataResponse.data

  return dataAttribute
}
