import styled from 'styled-components'
import tw from 'twin.macro'

export const Wrapper = tw.div`
    w-[20px]
    min-h-full
    relative
    flex
    flex-col
    gap[2.5px]
    items-center
    justify-center
`

export const Divider = tw.div`
    w-0
    z-0
    h-full
    absolute
    left-1/2
    top-1/2
    translate-x-[-50%]
    translate-y-[-50%]
    border-r
    border-color[#656565]
    opacity-[0.3]
    border-solid
`

export const SpreadInfo = tw.div`
    z-10
    bg-white
    h-[22px]
    min-w-[19px]
    border-radius[4px]
    px-1
    flex
    justify-center
    items-center
    font-bahnschrift
    font-semibold
    text-[12px]
    leading-[14px]
    text-brand-black
    cursor-default
`

export const ArrowBig = tw.div`
    w-[15px]
    h-[2px]
    bg-brand-light
    border-radius[1px]
`

export const ArrowSmall = tw.div`
    w-[9px]
    h-[2px]
    bg-gray-200
    border-radius[1px]
`

export const ArrowWrapper = styled.div<{ show: boolean }>`
  ${tw`flex flex-col items-center z-10 gap-0.5`}
  ${(props) => (props.show ? tw`opacity-100` : tw`opacity-0`)}
`
