import React from 'react'
import tw from 'twin.macro'

import { InputRow } from 'components/InputRow'
import { Currency } from 'types/currency'

const Container = tw.div`flex w-full relative`
const CurrencyContainer = tw.div`font-semibold text-white`
const ReqContainer = tw.div`text-gray-500`

export interface InputPriceProps {
  placeholder: string
  currency: Currency
  onChange: (nextPrice: string) => void
  value: string
  error?: string
}

export const InputPrice: React.FC<InputPriceProps> = ({
  placeholder,
  currency,
  onChange,
  value,
  error,
}) => {
  return (
    <Container>
      <InputRow
        value={value}
        type="number"
        onChange={onChange}
        placeholder={placeholder}
        error={error}
      >
        <ReqContainer>Required</ReqContainer>
        <CurrencyContainer>{currency.Symbol}</CurrencyContainer>
      </InputRow>
    </Container>
  )
}
