import tw from 'twin.macro'

export const Wrapper = tw.div`
    w-[225px]
    min-h-[65px]
    flex
    justify-center
    items-center
    p-[16px]
    gap-[16px]
    bg-gray-900
    border-radius[4px]
    box-shadow[0px 4px 4px rgba(0, 0, 0, 0.25), 0px 41px 79px rgba(0, 0, 0, 0.3), 0px 19.5949px 23.8162px rgba(0, 0, 0, 0.195477), 0px 14.1236px 9.89203px rgba(0, 0, 0, 0.15)]
    backdrop-filter[blur(16px)]
`

export const InfoContainer = tw.div`flex-1 `

export const Title = tw.div`
    font-bahnschrift
    font-semibold
    text-[14px]
    leading-[17px]
    text-white
`
export const Message = tw.div`
    w-full
    font-bahnschrift
    font-normal
    text-[12px]
    leading-[14px]
    text-white
    opacity-50
    whitespace-normal
    word-break[normal]
`
