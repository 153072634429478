import { FC, useState } from 'react'

import CurrencySelect from 'components/CurrencySelect'
import Popover from 'components/Popover'
import { Currency } from 'types/currency'

import {
  CurrencyPairDivider,
  CurrencyButton,
  CurrencySelectors,
  CurrencyPairToolbar,
  Wrapper,
} from './CurrencyPairHeader.styles'

export interface HeaderProps {
  baseCurrency: Currency
  quotedCurrency: Currency
  onBaseCurrencyChange: (e: { value: Currency }) => void
  onQuotedCurrencyChange: (e: { value: Currency }) => void
}

export const CurrencyPairHeader: FC<HeaderProps> = ({
  baseCurrency,
  quotedCurrency,
  onBaseCurrencyChange,
  onQuotedCurrencyChange,
}) => {
  const [showBaseCurrencySelect, setShowBaseCurrencySelect] =
    useState<boolean>(false)

  const [showQuotedCurrencySelect, setShowQuotedCurrencySelect] =
    useState<boolean>(false)

  const [
    baseCurrencyButtonReferenceElement,
    setBaseCurrencyButtonReferenceElement,
  ] = useState<HTMLButtonElement | null>(null)

  const [
    quotedCurrencyButtonReferenceElement,
    setQuotedCurrencyButtonReferenceElement,
  ] = useState<HTMLButtonElement | null>(null)

  return (
    <Wrapper>
      <CurrencyPairToolbar>
        <CurrencySelectors>
          <CurrencyButton
            size="small"
            ref={setBaseCurrencyButtonReferenceElement}
            onClick={() => {
              setShowQuotedCurrencySelect(false)
              setShowBaseCurrencySelect(!showBaseCurrencySelect)
            }}
            data-testid="base-currency-button"
          >
            <span>{baseCurrency.Symbol}</span>
          </CurrencyButton>
          <CurrencyPairDivider />
          <CurrencyButton
            size="small"
            ref={setQuotedCurrencyButtonReferenceElement}
            onClick={() => {
              setShowBaseCurrencySelect(false)
              setShowQuotedCurrencySelect(!showQuotedCurrencySelect)
            }}
            data-testid="quoted-currency-button"
          >
            <span>{quotedCurrency.Symbol}</span>
          </CurrencyButton>
        </CurrencySelectors>
      </CurrencyPairToolbar>
      {showBaseCurrencySelect && baseCurrencyButtonReferenceElement ? (
        <Popover
          referenceElement={baseCurrencyButtonReferenceElement}
          distance={8.5}
          placement="bottom"
        >
          <CurrencySelect
            onSelectValue={(e) => {
              setShowBaseCurrencySelect(false)
              onBaseCurrencyChange(e)
            }}
          />
        </Popover>
      ) : null}

      {showQuotedCurrencySelect && quotedCurrencyButtonReferenceElement ? (
        <Popover
          referenceElement={quotedCurrencyButtonReferenceElement}
          distance={8.5}
          placement="bottom"
        >
          <CurrencySelect
            baseCurrency={baseCurrency}
            onSelectValue={(e) => {
              setShowQuotedCurrencySelect(false)
              onQuotedCurrencyChange(e)
            }}
          />
        </Popover>
      ) : null}
    </Wrapper>
  )
}
