import { useEffect, useState } from 'react'
import 'twin.macro'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import Close from 'assets/icons/close.svg'
import Input from 'components/Input'
import { numberWithCommas } from 'lib/utils'
import { Currency } from 'types/currency'

import {
  Wrapper,
  AmountButton,
  CurrencySwitcher,
  RadioButton,
  AmountToolbar,
  ErrorContainer,
} from './TradeAmount.styles'

export interface TradeAmountProps {
  baseCurrency: Currency
  quotedCurrency: Currency
  maxAvailableBase: string
  maxAvailableQuoted: string
  error?: string
  amount: string
  onCurrencyChange?: (value: Currency) => void
  onAmountChange: (value: string) => void
}

export const TradeAmount: React.FC<TradeAmountProps> = ({
  baseCurrency,
  quotedCurrency,
  maxAvailableBase = '0.00',
  maxAvailableQuoted = '0.00',
  amount,
  error,
  onCurrencyChange,
  onAmountChange,
}) => {
  const [ignoreBlur, setIgnoreBlur] = useState<boolean>(false)
  const [showAmountButtons, setShowAmountButtons] = useState(false)

  // as default the currency selected will be the the first currency
  const [currencySelected, setCurrencySelected] =
    useState<Currency>(baseCurrency)

  const handleOnCurrencyChange = (e: { value: Currency }) => {
    const value = e.value
    setCurrencySelected(value)
    onCurrencyChange && onCurrencyChange(value)
  }

  const handleOnAmountChange = (value: string) => {
    if (Number(value) >= 0) {
      onAmountChange(value)
    }
  }

  const handleOnNumberFormatInputChange = (values: NumberFormatValues) => {
    const { value } = values
    handleOnAmountChange(value)
  }

  const getMaxAvailableByCurrencySelected = () => {
    if (currencySelected.Symbol === baseCurrency.Symbol) return maxAvailableBase
    return maxAvailableQuoted
  }

  const addPercentageAmount = (percentage: number) => {
    const newAmount = (
      Number(getMaxAvailableByCurrencySelected()) * percentage
    ).toFixed(2)
    onAmountChange(newAmount)
  }

  const handleBlur = () => {
    if (ignoreBlur) {
      return
    }
    setShowAmountButtons(false)
  }

  useEffect(() => {
    // if none currency is selected the base will be selected
    if (
      currencySelected.Symbol !== baseCurrency.Symbol &&
      currencySelected.Symbol !== quotedCurrency.Symbol
    ) {
      setCurrencySelected(baseCurrency)
    }
  }, [baseCurrency, quotedCurrency, currencySelected])

  return (
    <div>
      <Wrapper
        onBlur={handleBlur}
        onMouseDown={() => setIgnoreBlur(true)}
        onMouseUp={() => setIgnoreBlur(false)}
        onMouseOut={() => setIgnoreBlur(false)}
        data-testid="wrapper"
      >
        <NumberFormat
          placeholder="Value"
          value={amount}
          thousandSeparator={true}
          customInput={Input}
          onFocus={() => setShowAmountButtons(true)}
          error={error && error.length > 0}
          onValueChange={handleOnNumberFormatInputChange}
          tw="pr-[55%] w-full"
        />
        <CurrencySwitcher>
          <span tw="opacity-50 mr-[3px]">
            Max: {numberWithCommas(getMaxAvailableByCurrencySelected())}
          </span>
          <RadioButton
            selected={currencySelected.Symbol === baseCurrency.Symbol}
            onClick={() => handleOnCurrencyChange({ value: baseCurrency })}
          >
            {baseCurrency.Symbol}
          </RadioButton>
          <RadioButton
            selected={currencySelected.Symbol === quotedCurrency.Symbol}
            onClick={() => handleOnCurrencyChange({ value: quotedCurrency })}
          >
            {quotedCurrency.Symbol}
          </RadioButton>
        </CurrencySwitcher>
        {showAmountButtons ? (
          <div tw="absolute right-0 left-0 top-[26px] min-h-0 flex items-center px-2.5">
            <AmountToolbar
              tabIndex={0}
              onBlur={() => setShowAmountButtons(false)}
            >
              <div
                tw="inline-flex items-center gap-[3.45px]"
                data-testid="percentage-buttons"
              >
                <AmountButton
                  size="small"
                  name="percentage-10"
                  onClick={() => {
                    addPercentageAmount(0.1)
                    setShowAmountButtons(false)
                  }}
                >
                  10%
                </AmountButton>
                <AmountButton
                  size="small"
                  name="percentage-25"
                  onClick={() => {
                    addPercentageAmount(0.25)
                    setShowAmountButtons(false)
                  }}
                >
                  25%
                </AmountButton>
                <AmountButton
                  size="small"
                  name="percentage-50"
                  onClick={() => {
                    addPercentageAmount(0.5)
                    setShowAmountButtons(false)
                  }}
                >
                  50%
                </AmountButton>
                <AmountButton
                  size="small"
                  name="percentage-75"
                  onClick={() => {
                    addPercentageAmount(0.75)
                    setShowAmountButtons(false)
                  }}
                >
                  75%
                </AmountButton>
                <AmountButton
                  size="small"
                  name="percentage-max"
                  onClick={() => {
                    addPercentageAmount(1)
                    setShowAmountButtons(false)
                  }}
                >
                  100%
                </AmountButton>
              </div>
              <AmountButton
                size="small"
                onClick={() => setShowAmountButtons(false)}
                aria-label="close percentage button box"
              >
                <Close tw="w-[16px] fill-gray-500" />
              </AmountButton>
            </AmountToolbar>
          </div>
        ) : null}
      </Wrapper>
      {error ? <ErrorContainer>{error}</ErrorContainer> : null}
    </div>
  )
}
