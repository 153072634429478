import styled from 'styled-components'
import tw from 'twin.macro'

import { Side } from 'lib/interfaces'

export const Button = styled.button<{
  typeButton: Side
}>(({ typeButton }) => [
  tw`
      pt-[3px]
      px-2.5
      min-w-[131px]
      h-[76px]
      flex
      flex-col
      justify-start
      items-center
      text-white
      border-radius[4px]
      hover:bg-[rgba(0, 0, 0, 0.2)]
  `,
  typeButton === 'buy' && tw`focus:bg-brand-green`,
  typeButton === 'sell' && tw`focus:bg-brand-red`,
])

export const TypeButtonTitle = styled.span`
  ${tw`
    font-bahnschrift
    font-normal
    text-[10px]
    leading-3
    color[rgba(255, 255, 255, 0.5)]
    group-focus:text-white
  `}
`

export const TypeButtonTitleBuy = styled(TypeButtonTitle)`
  ${tw`group-hover:text-brand-green`}
`

export const TypeButtonTitleSell = styled(TypeButtonTitle)`
  ${tw`group-hover:text-brand-red`}
`

export const PriceInteger = tw.span`
    font-bahnschrift
    font-normal
    text-[32px]
    leading-[38px]
    color[gba(255, 255, 255, 0.5)]
`
export const PriceDecimal = tw.span`
    font-bahnschrift
    font-normal
    text-[12px]
    leading-[14px]
    text-white
    opacity-40
`
