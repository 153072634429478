import styled from 'styled-components'
import tw from 'twin.macro'

import Button from 'components/Button'

export const Wrapper = tw.div`
  relative
`
export const CurrencySwitcher = tw.div`
  absolute
  right-2
  top-0
  bottom-0
  flex
  items-center
  font-bahnschrift
  font-normal
  text-[12px]
  text-gray-500
`

export const RadioButton = styled.button<{ selected?: boolean }>`
  ${tw`text-gray-500 px-0.5 py-[4px] border-radius[4px]`}
  ${tw`hover:bg-[rgb(55, 55, 55)] hover:text-white`}
  ${({ selected }) => selected && tw`font-semibold text-white`}
`

export const AmountButton = styled(Button)`
  ${tw`px-1
    py-0.5
    border-transparent
    font-bahnschrift
    font-normal
    text-[12px]
    leading-[14px]
    text-gray-500
  `}
  ${tw`hover:bg-[rgb(113,113,113)] hover:border-transparent`}
  ${tw`active:border-transparent`}
`

export const AmountToolbar = tw.div`
  w-full
  flex
  justify-between
  items-center
  bg-gray-800
  border-radius[2px]
  p-1
  box-shadow[0px 4px 4px rgba(0, 0, 0, 0.25), 0px 41px 79px rgba(0, 0, 0, 0.3), 0px 19.5949px 23.8162px rgba(0, 0, 0, 0.195477), 0px 14.1236px 9.89203px rgba(0, 0, 0, 0.15)]
  backdrop-blur-[16px]
  z-10
`
export const ErrorContainer = tw.div`
  p-2
  text-brand-red
  font-bahnschrift
  text-[12px]
  leading-[14px]
`
