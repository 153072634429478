import {
  Dispatch,
  FC,
  useState,
  SetStateAction,
  KeyboardEvent,
  useMemo,
} from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { monthNames } from 'lib/constants'

import { DatePickerStyles, Container } from './DatePicker.styles'
import { DatePickerHeader } from './DatePickerHeader'
import { GridPopOver } from './GridPopOver'
import { TimePicker } from './TimePicker'

export interface DatePickerProps {
  setDate: Dispatch<SetStateAction<Date>>
  date?: Date
}

export const DatePicker: FC<DatePickerProps> = ({ setDate, date }) => {
  const [localDate, setLocalDate] = useState<Date>(date || new Date())
  const [showMonthPicker, setShowMonthPicker] = useState<boolean>(false)

  const [increaseMonth, decreaseMonth, setMonth, setHours, setMinutes] =
    useMemo(() => {
      const increaseMonth = () => {
        setLocalDate((prev) => {
          const nextDate = new Date(prev)
          nextDate.setMonth(nextDate.getMonth() + 1)
          return nextDate
        })
      }

      const decreaseMonth = () => {
        setLocalDate((prev) => {
          const nextDate = new Date(prev)
          nextDate.setMonth(nextDate.getMonth() - 1)
          return nextDate
        })
      }

      const setMonth = (month: number) => {
        setLocalDate((prev) => {
          const nextDate = new Date(prev)
          nextDate.setMonth(month)
          console.log(nextDate)
          return nextDate
        })
      }

      const setHours = (hours: number) => {
        setLocalDate((prev) => {
          const nextDate = new Date(prev)
          nextDate.setHours(hours)
          return nextDate
        })
      }

      const setMinutes = (minutes: number) => {
        setLocalDate((prev) => {
          const nextDate = new Date(prev)
          nextDate.setMinutes(minutes)
          return nextDate
        })
      }
      return [increaseMonth, decreaseMonth, setMonth, setHours, setMinutes]
    }, [setLocalDate])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      setDate(localDate)
    }
  }

  return (
    <Container onKeyDown={handleKeyDown}>
      <DatePickerStyles>
        <TimePicker
          date={localDate}
          setHours={setHours}
          setMinutes={setMinutes}
        />
        <DatePickerHeader
          date={localDate}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          showMonthPicker={showMonthPicker}
          toggleMonthPicker={() => setShowMonthPicker((prev) => !prev)}
        />
        <ReactDatePicker
          renderCustomHeader={() => <></>}
          inline
          onChange={(date) => {
            setLocalDate(date)
          }}
          selected={localDate}
          disabledKeyboardNavigation
        />
        {showMonthPicker && (
          <GridPopOver
            handleClick={(_, index) => {
              setMonth(index)
              setShowMonthPicker(false)
            }}
            items={monthNames.map((m) => m.substring(0, 3))}
          />
        )}
      </DatePickerStyles>
    </Container>
  )
}
