import { useEffect } from 'react'

import { useCanonicalSizeBuckets } from 'hooks/useCanonicalSizeBuckets'
import { useNormalSize } from 'hooks/useNormalSize'
import { CurrencyPair } from 'types/currency-pair'

export const useEphemeralSubscriptionData = (
  currencyPair: CurrencyPair,
  setNormalSize: (normalSize: string) => void,
  setSizeBuckets: (sizeBuckets: string[]) => void,
): void => {
  // TBD standardize this subscription
  const ephemeralSubscriptionNormalSize = useNormalSize(currencyPair)
  useEffect(() => {
    if (ephemeralSubscriptionNormalSize) {
      setNormalSize(ephemeralSubscriptionNormalSize)
    }
  }, [ephemeralSubscriptionNormalSize, setNormalSize])

  // TBD standardize this subscription
  const ephemeralSubscriptionSizeBuckets = useCanonicalSizeBuckets(currencyPair)
  useEffect(() => {
    if (ephemeralSubscriptionSizeBuckets) {
      setSizeBuckets(ephemeralSubscriptionSizeBuckets)
    }
  }, [ephemeralSubscriptionSizeBuckets, setSizeBuckets])
}
