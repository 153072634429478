import { selectorFamily, atom } from 'recoil'

import { QuoteResponseData } from 'types/api/quote-response'

export const quoteReponses = atom({
  key: 'QuoteResponseData',
  default: Array<QuoteResponseData>(),
})

export const quoteResponseState = selectorFamily({
  key: 'QuoteResponseDataQuery',
  get:
    (id: string) =>
    ({ get }) => {
      const quotes = get(quoteReponses)
      const quote = quotes.filter((quote) => quote.QuoteReqID === id)

      if (quote.length === 1) return quote[0]
      else return null
    },
})
