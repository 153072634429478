import { InputRow } from 'components/InputRow'

import { Percetage, Optional } from './InputVariance.styles'

export interface InputVarianceProps {
  value: string
  error?: string
  onChange: (value: string) => void
}

export const InputVariance: React.FC<InputVarianceProps> = ({
  onChange,
  value,
  error,
}) => {
  const isAllowed = (value: string) =>
    Number(value) >= 0 && Number(value) <= 100

  return (
    <InputRow
      value={value}
      type="number"
      onChange={onChange}
      placeholder={'Variance'}
      error={error}
      isAllowed={isAllowed}
    >
      <Optional>Optional</Optional>
      <Percetage>%</Percetage>
    </InputRow>
  )
}
