import { useMemo } from 'react'

import { useSocket } from 'hooks/useSocket'
import { useSubscription } from 'hooks/useSubscription'
import { BalanceParameters, BalanceResponse } from 'types/api/balance'

export const useBalance = (
  Currencies?: string[] | null,
  EquivalentCurrency?: string | null,
): BalanceResponse[] | null => {
  const socket = useSocket()

  const balanceParameters: BalanceParameters = useMemo(
    () => ({
      name: 'Balance',
      Currencies,
      EquivalentCurrency,
    }),
    [Currencies, EquivalentCurrency],
  )

  const balance = useSubscription<BalanceResponse>(socket, balanceParameters)

  return balance
}
