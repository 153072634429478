import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = tw.div`absolute top-[32px] left-[20px] z-10 bg-gray-700 px-3 rounded-[4px]`

export const DatePickerStyles = styled.div`
  margin: 0;
  padding: 0;
  .react-datepicker__month-container {
    ${tw`p-0 m-0 border-0`}
  }

  .react-datepicker {
    border: none;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .react-datepicker__day-names {
    ${tw`bg-gray-700 p-0 m-0 border-0`}
  }

  .react-datepicker__day-name {
    ${tw`font-bahnschrift text-gray-200`}
  }

  .react-datepicker__month {
    ${tw`font-bahnschrift bg-gray-700 p-0 m-0 border-0`}
  }

  .react-datepicker__day {
    ${tw`text-gray-200`}
  }

  .react-datepicker__day:hover {
    ${tw`text-gray-900`}
  }

  .react-datepicker__day--keyboard-selected {
  }

  .react-datepicker__day--selected {
    ${tw`font-normal bg-white rounded-[4px] text-gray-900`}
  }

  .react-datepicker__day--outside-month {
    ${tw`text-gray-500`}
  }

  .react-datepicker__header {
    ${tw`bg-gray-700 p-0 pb-[5px] m-0 border-0`}
  }
`
