import { atom, RecoilState, RecoilValueReadOnly, selector } from 'recoil'

import identify from 'lib/utils/identify'
import { getRandomCurrencyPairs } from 'lib/utils/simulation'
import { CurrencyPair } from 'types/currency-pair'

const INITIAL_ALPHA_NUM_PAIRS = 24

type MockPairsBoardSlice = {
  id: RecoilState<string>
  pairs: RecoilState<CurrencyPair[]>
}

export type MockPairsSlice = {
  groups: RecoilState<Record<string, CurrencyPair[]>>
  idSet: RecoilValueReadOnly<string[]>
  count: RecoilValueReadOnly<number>
  board: MockPairsBoardSlice
}

const groups = atom<Record<string, CurrencyPair[]>>({
  key: 'mockPairs.groups',
  default: { alpha: getRandomCurrencyPairs(INITIAL_ALPHA_NUM_PAIRS) },
})

const idSet = selector<string[]>({
  key: 'mockPairs.idSet',
  get: ({ get }) => {
    const currencyPairsGroups = get(groups)

    const resultSet = new Set(
      Object.values(currencyPairsGroups).flatMap((currencyPairs) =>
        currencyPairs.map((currencyPair) => {
          return identify.currencyPair(currencyPair)
        }),
      ),
    )

    return Array.from(resultSet.values())
  },
})

const count = selector<number>({
  key: 'mockPairs.count',
  get: ({ get }) => get(idSet).length,
})

const currencyPairsBoardId = atom<string>({
  key: 'mockPairs.board.id',
  default: 'alpha',
})

export const board: MockPairsBoardSlice = {
  id: currencyPairsBoardId,
  pairs: selector<CurrencyPair[]>({
    key: 'mockPairs.board.pairs',
    get: ({ get }) => get(groups)[get(currencyPairsBoardId)] ?? [],
    set: ({ get, set }, newValue) => {
      const prevCurrencyPairs = get(groups)
      const nextCurrencyPairs = {
        ...prevCurrencyPairs,
        [get(currencyPairsBoardId)]: newValue,
      }
      set(groups, nextCurrencyPairs)
    },
  }),
}

export const mockPairs: MockPairsSlice = {
  board,
  groups,
  idSet,
  count,
}
