import { useCallback } from 'react'
import tw from 'twin.macro'

import { InputRow } from 'components/InputRow'
import Select from 'components/Select'
import { SelectData } from 'components/Select/Select'
import { ClipIntervalLabels } from 'lib/constants'
import { ClipIntervalType } from 'lib/interfaces'

const ReqContainer = tw.div`text-gray-500`

const selectData: SelectData[] = Object.entries(ClipIntervalLabels).map(
  ([key, value]: [key: ClipIntervalType, value: string]) => ({
    label: value,
    value: key,
  }),
)

export interface ClipIntervalProps {
  value: string
  clipIntervalPeriod: ClipIntervalType
  error?: string
  onChange: (input: string, clipIntervalPeriod: ClipIntervalType) => void
}

export const ClipInterval: React.FC<ClipIntervalProps> = ({
  onChange,
  value,
  clipIntervalPeriod = 'seconds',
  error,
}) => {
  const handleSelectOnChange = useCallback(
    (selectValue: string) => {
      const clipIntervalPeriod = selectValue as ClipIntervalType
      onChange(value, clipIntervalPeriod)
    },
    [onChange, value],
  )
  return (
    <InputRow
      value={value}
      type="number"
      onChange={(value: string) => {
        onChange(value, clipIntervalPeriod)
      }}
      placeholder={'Clip Interval'}
      error={error}
    >
      <ReqContainer>Required</ReqContainer>
      <Select
        data={selectData}
        value={clipIntervalPeriod}
        onChange={(e) => handleSelectOnChange(e.value.value)}
      />
    </InputRow>
  )
}
