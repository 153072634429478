import { atom } from 'recoil'

import { TalosApiError } from 'types/api/error'

/**
 * store.socketMeta
 *
 * Note that the socket is an object and distributed through the app via React Context.
 * However we may want to post statistics or ancillary information that is low level,
 * associated with the socket, and don't want to update the widely shared context with
 * fast changing secondary info.
 */

const latestError = atom<TalosApiError>({
  key: 'socket.latestError',
  default: null,
})

export const socketMeta = {
  latestError,
}
