import { ReactElement, useCallback } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'

import Input from 'components/Input'
import 'twin.macro'

import {
  Container,
  ErrorContainer,
  RightContainer,
  Wrapper,
} from './InputRow.styles'

export interface InputRowProps {
  error?: string
  children?: ReactElement | ReactElement[]
  onChange: (quantity: string) => void
  type: 'number' | 'datetime' | 'text'
  placeholder?: string
  value: string
  isAllowed?: (value: string) => boolean // A checker function to check if input value is valid or not. If this function returns false, the onChange method will not get triggered. NOTE: Works only when the type is 'number'
}

export const InputRow: React.FC<InputRowProps> = ({
  error,
  children,
  onChange,
  placeholder,
  type,
  value,
  isAllowed,
}) => {
  const numberFormatOnChange = useCallback(
    (values: NumberFormatValues) => {
      const { value } = values
      onChange(value)
    },
    [onChange],
  )

  const inputOnChange = useCallback(
    (ev) => {
      onChange(ev.target.value)
    },
    [onChange],
  )

  return (
    <Wrapper>
      <Container>
        {type === 'number' ? (
          <NumberFormat
            value={value}
            thousandSeparator={true}
            customInput={Input}
            placeholder={placeholder}
            error={error?.trim().length > 0}
            onValueChange={numberFormatOnChange}
            isAllowed={(values) => (isAllowed ? isAllowed(values.value) : true)}
            tw="pr-[55%] w-full"
          />
        ) : (
          <Input
            value={value}
            error={error?.trim().length > 0}
            onChange={inputOnChange}
            type={type}
            placeholder={placeholder}
            tw="pr-[55%] w-full"
          />
        )}
        <RightContainer>{children}</RightContainer>
      </Container>
      {error ? <ErrorContainer>{error}</ErrorContainer> : null}
    </Wrapper>
  )
}
