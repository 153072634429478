import { useState } from 'react'
import 'twin.macro'

import { createReqId } from 'lib/utils'
import { NotificationData, NotificationDataWithID } from 'types/notification'

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<NotificationDataWithID[]>(
    [],
  )

  const add = (content: NotificationData) =>
    setNotifications((currentToasts) => [
      ...currentToasts,
      { id: createReqId().toString(), ...content },
    ])

  const remove = (id: string) =>
    setNotifications((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id),
    )

  return { notifications, add, remove }
}
