import tw from 'twin.macro'

export const Wrapper = tw.div`w-full`

export const Container = tw.div`flex w-full relative`

export const RightContainer = tw.div`
  absolute
  right-2
  top-0
  bottom-0
  flex
  gap-1
  items-center
  px-0.5
  font-bahnschrift
  font-normal
  text-[12px]
  text-gray-500
`

export const ErrorContainer = tw.div`
  p-2
  text-brand-red
  font-bahnschrift
  text-[12px]
  leading-[14px]
`
