import React from 'react'

import Grid from 'components/StreamingTile/Grid'
import { MarketDataSnapshot } from 'types/api/market-data-snapshot'
import { CurrencyPair } from 'types/currency-pair'
import { MarketDataBucket } from 'types/market-data-bucket'

import { useZippedBidsAndOffers } from './useZippedBidsAndOffers'

export interface VolumeLadderProps {
  currencyPair: CurrencyPair
  marketDataSnapshot: MarketDataSnapshot
  sizeBuckets: string[]
  setSizeBuckets: (sb: string[]) => void
}

export const VolumeLadder: React.FC<VolumeLadderProps> = ({
  marketDataSnapshot,
  sizeBuckets,
  setSizeBuckets,
}) => {
  const zippedBidsAndOffers: MarketDataBucket[] = useZippedBidsAndOffers(
    marketDataSnapshot,
    sizeBuckets,
  )

  if (zippedBidsAndOffers) {
    return (
      <Grid buckets={zippedBidsAndOffers} setSizeBuckets={setSizeBuckets} />
    )
  }
  return null
}
