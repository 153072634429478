import { FC, useState, Dispatch, SetStateAction } from 'react'
import tw from 'twin.macro'

import Calendar from 'assets/icons/calendar.svg'
import Close from 'assets/icons/close.svg'
import DatePicker from 'components/InputRow/DatePicker'

import { InputRow } from './InputRow'

const OptionalContainer = tw.div`text-gray-500`
const Container = tw.div`relative w-full`

const parseDate = (date?: Date) => {
  if (!date) return ''

  const year = date.getFullYear().toString().substring(2)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return `${year}/${month}/${day} - ${hours}:${minutes}`
}

export interface DateInputRowProps {
  type: 'start' | 'stop'
  date: Date | null
  setDate: Dispatch<SetStateAction<Date>>
}

export const DateInputRow: FC<DateInputRowProps> = ({
  type,
  date,
  setDate,
}) => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)
  const placeholder = type === 'start' ? 'Start time' : 'End time'
  return (
    <Container>
      <InputRow
        value={parseDate(date)}
        onChange={() => {}}
        type="text"
        placeholder={placeholder}
      >
        <OptionalContainer>Optional</OptionalContainer>
        <button onClick={() => setOpenDatePicker((prev) => !prev)}>
          {!openDatePicker && <Calendar tw="w-[16px] h-[16px]" />}
          {openDatePicker && <Close tw="fill-white w-[16px] h-[16px]" />}
        </button>
      </InputRow>
      {openDatePicker && <DatePicker date={date} setDate={setDate} />}
    </Container>
  )
}
