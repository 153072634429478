import {
  atom,
  RecoilState,
  RecoilValueReadOnly,
  selector,
  selectorFamily,
} from 'recoil'

import identify from 'lib/utils/identify'
import { getDemoStreamingTiles } from 'lib/utils/simulation'
import { Group, GroupId, GroupPosition } from 'types/group'
import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'

type HashedSliver = [string, StreamingTileLocalSliver]
type StreamingTileSubscriptionsMap = Record<string, StreamingTileLocalSliver>

export type StreamingTilesSlice = {
  selectedGroupId: RecoilState<GroupId>
  groups: RecoilState<Record<GroupId, Group>>
  groupById: (groupId: GroupId) => RecoilState<Group>
  lookupByPosition: (
    groupPosition: GroupPosition,
  ) => RecoilState<StreamingTileLocalSliver>
  streamingTilesMounted: RecoilValueReadOnly<StreamingTileSubscriptionsMap>
}

const DEMO_INITIAL_NUM_PAIRS = 6

const selectedGroupId = atom<GroupId>({
  key: 'streamingTiles.selectedGroupId',
  default: 'Alfa',
})

const groups = atom<Record<GroupId, StreamingTileLocalSliver[]>>({
  key: 'streamingTiles.groups',
  default: { Alfa: getDemoStreamingTiles(DEMO_INITIAL_NUM_PAIRS) },
})

const groupById = selectorFamily<Group, GroupId>({
  key: 'streamingTiles.groupById',
  get:
    (groupId) =>
    ({ get }) => {
      const grps = get(groups)

      if (!groupId || !grps) return []

      return grps[groupId] ?? []
    },
  set:
    (groupId) =>
    ({ get, set }, nextVal) => {
      const prevGroups = get(groups)
      const nextGroups = {
        ...prevGroups,
        [groupId]: nextVal,
      }
      set(groups, nextGroups)
    },
})

const lookupByPosition = selectorFamily<
  StreamingTileLocalSliver,
  GroupPosition
>({
  key: 'streamingTiles.lookupByPosition',
  get:
    ({ groupId, position }) =>
    ({ get }) =>
      get(groupById(groupId))[position],
  set:
    ({ groupId, position }) =>
    ({ get, set }, nextSliver: StreamingTileLocalSliver) => {
      const grp = get(groupById(groupId))
      const nextGrp = grp.map((prevSliver, i) =>
        position === i ? nextSliver : prevSliver,
      )
      set(groupById(groupId), nextGrp)
    },
})

const streamingTilesMounted = selector<
  Record<string, StreamingTileLocalSliver>
>({
  key: 'streamingTiles.streamingTilesMounted',
  get: ({ get }) => {
    const displayedGroupId = get(selectedGroupId)
    const displayedGroup = get(groupById(displayedGroupId))

    const hashedSlivers = displayedGroup.map(
      (streamingTileLocalSliver) =>
        [
          identify.streamingTileLocalSliver(streamingTileLocalSliver),
          streamingTileLocalSliver,
        ] as HashedSliver,
    )

    return hashedSlivers.reduce(
      (res, [hashId, sliver]) => ({
        ...res,
        [hashId]: sliver,
      }),
      {},
    )
  },
})

export const streamingTiles: StreamingTilesSlice = {
  selectedGroupId,
  groups,
  groupById,
  lookupByPosition,
  streamingTilesMounted,
}
