import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export interface ListItemProps {
  disabled?: boolean
}

export const ListItem = styled.li<ListItemProps>`
  ${tw`
    min-h-[32px]
    flex
    justify-between
    items-center
    px-2
    py-1
    bg-[rgba(255, 255, 255, 0.15)]
    border-radius[2px]
    font-bahnschrift
    font-normal
    text-[12px]
    leading-[14px]
    text-white
    cursor-pointer
  `}

  ${({ disabled }) =>
    disabled
      ? tw`text-opacity-30 cursor-default`
      : css`
          ${tw`hover:bg-[rgba(255, 255, 255, 0.25)] hover:text-white`}
          ${tw`active:bg-[rgba(255, 255, 255, 0.45)]`}
        `}
`
