import React, { useEffect, useState } from 'react'
import 'twin.macro'

import ChevronDown from 'assets/icons/chevron-down.svg'
import Button from 'components/Button'
import List from 'components/List'
import ListItem from 'components/ListItem'

export interface SelectData {
  label: string
  value: string
}
export interface SelectProps {
  data: SelectData[]
  value?: SelectData['value']
  onChange: (e: { value: SelectData }) => void
}

export const Select: React.FC<SelectProps> = ({ data, value, onChange }) => {
  const [currentValue, setCurrentValue] = useState<SelectData['value'] | null>(
    value ?? data ? data[0].value : null,
  )

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  const [showList, setShowList] = useState<boolean>(false)

  const getLabelFromValue = (
    value: SelectData['value'],
  ): SelectData['label'] | null => {
    const found = data.find((element) => element.value === value)

    if (found) return found.label

    return null
  }

  // Event handler for keydowns
  const handleKeyDown = (value: SelectData['value']) => (e) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        if (currentValue !== value) {
          e.preventDefault()
          setCurrentValue(value)
          setShowList(false)
          onChange({ value: { label: getLabelFromValue(value), value } })
        }
        break
      default:
        break
    }
  }

  const Option = (value: SelectData['value'], label: SelectData['label']) => (
    <ListItem
      id={value}
      role="option"
      aria-selected={currentValue === value}
      disabled={currentValue === value}
      tabIndex={0}
      key={value}
      onKeyDown={handleKeyDown(value)}
      onClick={() => {
        if (currentValue !== value) {
          setCurrentValue(value)
          setShowList(false)
          onChange({ value: { label, value } })
        }
      }}
    >
      {label}
    </ListItem>
  )

  const Options = data.map((item) => Option(item.value, item.label))

  return (
    <div tw="relative max-w-min">
      <Button
        size="small"
        aria-haspopup="listbox"
        aria-expanded={showList}
        aria-label={getLabelFromValue(currentValue)}
        onClick={() => setShowList(!showList)}
      >
        <span tw="whitespace-nowrap">{getLabelFromValue(currentValue)}</span>
        <ChevronDown tw=" ml-[4px] w-[16px] fill-gray-800" />
      </Button>
      {showList ? (
        <List
          role="listbox"
          aria-activedescendant={currentValue}
          tabIndex={-1}
          tw="absolute top-[calc(100% + 5.5px)] right-0 min-w-[107px] z-50"
        >
          {Options}
        </List>
      ) : null}
    </div>
  )
}
