import tw from 'twin.macro'

import Button from 'components/Button'

export const Wrapper = tw.div`
    bg-[#1F1F1F]
    flex
    justify-between
    items-center
    pl-[9px]
    pr-[3px]
`

export const Toolbar = tw.div`flex gap-2 items-center`

export const CurrencyPairToolbar = tw.div`flex gap-[4px] items-center`

export const CurrencyPairDivider = tw.div`
    w-[1px]
    h-[19px]
    bg-gray-800
    transform[rotate(11.89deg)]
`

export const CurrencyPair = tw.span`
    text-brand-light
    font-bahnschrift
    font-normal
    text-[12px]
    leading-[14px]
    text-center
    flex
    items-center
    gap-[4px]
    cursor-pointer
`

export const CurrencyButton = tw(Button)`
    p-1
`

export const Select = tw.select`
    h-[23px]
    w-[114px]
    bg-white
    border-[1px]
    border-gray-500
    border-radius[8px]
    text-[#6A6A6A]
    pl-[7px]
    outline-none
    appearance-none
    font-light
    text-[12px]
    background-image[url(./icons/arrow-drop-down.svg)]
    background-position-x[100%]
    bg-no-repeat
`
