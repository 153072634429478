import styled from 'styled-components'
import tw from 'twin.macro'

export const Wrapper = styled.div`
  ${tw`
    min-w-[308px]
    max-w-min
    bg-[#1F1F1F]
    border
    border-solid
    border-[rgba(101, 101, 101, 0.5)]
    border-radius[4px]
    p-2
    flex
    flex-col
    gap-2
    `}

  ${tw`hover:border-[#A0A0A0]`}
`

export const NotificationsAnchor = tw.div``
