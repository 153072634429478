import styled from 'styled-components'
import tw from 'twin.macro'

export interface InputProps {
  error?: boolean
  hiddenStyle?: boolean
}

export const Input = styled.input<InputProps>`
  ${tw`
    h-[32px]
    p-2
    border-radius[4px]
    border
    border-color[rgba(0, 0, 0, 0.3)]
    bg-[rgba(255, 255, 255, 0.05)]
    font-bahnschrift
    font-normal
    text-[12px]
    leading-[14px]
    text-gray-500
    outline-none
    placeholder:text-gray-500
  `}

  ${tw`
    hover:bg-[rgba(255, 255, 255, 0.05)]
    hover:border-color[rgba(255, 255, 255, 0.2)]
  `}

  ${tw`
    focus:bg-[rgba(255, 255, 255, 0.03)]
    focus:text-white
    focus:border
    focus:border-color[rgba(255, 255, 255, 0.4)]
  `}
  
  ${({ error }) =>
    error
      ? tw`border-brand-red hover:border-brand-red focus:border-brand-red`
      : null}

  ${({ hiddenStyle }) =>
    hiddenStyle ? tw`border-transparent bg-transparent` : null}
`
