import dynamic from 'next/dynamic'

const ClientSideToplevel = dynamic(
  () => import('components/ClientSideToplevel/ClientSideToplevel'),
  {
    ssr: false,
  },
)

export default ClientSideToplevel
