import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'
import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'

import { range } from './utils'

const getRandomCapitalLetter = () =>
  String.fromCharCode(Math.floor(Math.random() * 26) + 'A'.charCodeAt(0))

const getRandomCapitalLetters = (n: number): string => {
  const rec = (n) => (0 === n ? [] : [getRandomCapitalLetter(), ...rec(n - 1)])

  return rec(n).join('')
}

export const getRandomCurrency = (): Currency => ({
  Symbol: getRandomCapitalLetters(3),
  Description: getRandomCapitalLetters(30),
})

export const getRandomCurrencyPair = (): CurrencyPair => [
  getRandomCurrency(),
  getRandomCurrency(),
]

export const getRandomCurrencyPairs = (n: number): CurrencyPair[] => {
  const newCurrencyPairs = []

  for (let i = n; i > 0; i--) {
    newCurrencyPairs.push(getRandomCurrencyPair())
  }

  return newCurrencyPairs
}

export const getRandomInitialPrice = () => 5 + 95 * Math.random()

export const randomPriceFluct = (prevPrice: number) => {
  const MAX_RANDOM_PRICE_FLUCT = 0.01

  const absFluct = Math.random() * MAX_RANDOM_PRICE_FLUCT
  const fluct = Math.random() < 0.5 ? -1 * absFluct : absFluct
  return fluct + (prevPrice ?? getRandomInitialPrice())
}

export const getDemoStreamingTiles = (n: number): StreamingTileLocalSliver[] =>
  range(n)
    .map(
      () =>
        [
          { Symbol: 'BTC', Description: 'Bitcoin' },
          { Symbol: 'USDC', Description: 'USD coin' },
        ] as CurrencyPair,
    )
    .map((currencyPair) => ({
      currencyPair,
      amount: '',
      normalSize: '',
      sizeBuckets: [],
    }))
