import { ClipIntervalType } from 'lib/interfaces'

export const parseInterval = (type: ClipIntervalType, amount: string) => {
  if (type == 'hours') {
    return `${amount}h`
  } else if (type == 'minutes') {
    return `${amount}m`
  } else if (type === 'seconds') {
    return `${amount}s`
  }
}
